.font-display {
  @apply text-5xl font-bold leading-tight tracking-tight;
}

body {
  @apply font-body;
}

h1, h2, h3, h4, h5, h6 {
  @apply font-header;
}

h1, .h1 {
  @apply text-4xl font-bold leading-tight tracking-tight;
}

h2, .h2 {
  @apply text-3xl font-bold leading-tight;
}

h3, .h3 {
  @apply text-2xl font-bold leading-normal;
}

h4, .h4 {
  @apply text-xl font-bold leading-normal;
}

h5, .h5 {
  @apply text-lg font-bold leading-normal;
}

h6, .h6 {
  @apply font-bold;
}

.link {
  @apply text-primary-500;

  &:hover,
  &:focus {
    @apply text-primary-600;
  }
}

.column-title {
  @apply  font-semibold uppercase text-xs;
  div {
    @apply px-4 py-3;
  }
}

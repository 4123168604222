@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }

}
/* Tells browser to use dark mode scroll bars, etc */
.dark {
  color-scheme: dark;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.main {
  flex: 1;
}

a {
  @apply text-primary-600;

  &:hover,
  &:focus {
    @apply text-primary-500;

    transform: none;
  }
}

ul {
  @apply list-disc list-inside;
}

ol {
  @apply list-decimal list-inside;
}

.table {
  @apply w-full;

  th,
  td {
    @apply align-top;
  }

}

.table-responsive {
  @apply block w-full overflow-x-auto;
}

.left-nav-group {
  @apply flex flex-col w-full first:border-b first:border-border-primary pb-7 mb-7;
}

.left-nav-item {
  @apply hover:bg-unifocalTurquoise-700 w-full list-none text-center;
}

.left-nav-link {
  @apply flex flex-col items-center justify-center w-[96px] py-3;
  @apply [&.active]:bg-unifocalTurquoise-700 [&.active]:border-l-4 [&.active]:border-l-unifocalTurquoise-400 [&.active]:font-semibold;
}

.left-nav-item:hover .left-nav-image {
  @apply fill-unifocalTurquoise-200;
  /* Change to desired hover color */
}

.left-nav-image {
  @apply fill-unifocalTurquoise-400 mx-auto h-8 w-8;
}

.left-nav-link.active .left-nav-image {
  @apply fill-unifocalTurquoise-200;
  /* Change to desired active color */
}

.left-nav-text {
  @apply text-white text-[10px];
}

.project-nav {
  @apply flex flex-row gap-1 items-center hover:cursor-pointer;
}

.project-nav.active {
  @apply text-unifocalTurquoise-900 !border-b-4 !border-unifocalTurquoise-400;
}

.project-nav.active svg path {
  @apply fill-unifocalTurquoise-600;
}

.daterangepicker {
  color: black;
}